function showModal(modalId) {
    jQuery('#' + modalId).modal('show');
}

function hideModal(modalId) {
    jQuery('#' + modalId).modal('hide');
}

function getElementByIdForParsley(id) {
    return jQuery('#' + id);
}

function refreshSelectPickerById(id) {
    jQuery('#' + id).selectpicker('refresh')
}

function refreshDOMContentLoadedEvent() {
    // Erstelle ein benutzerdefiniertes DOMContentLoaded-Event
    const domContentLoadedEvent = new Event('DOMContentLoaded');

    // Manuell das DOMContentLoaded-Event auslösen
    document.dispatchEvent(domContentLoadedEvent);
}

function checkFieldExistsAndSetValue(field, value, validation) {
    if (field && value !== null && value !== '') {
        if (validation) {
            field.classList.remove('parsley-error');
            field.classList.add('parsley-success');
        }

        field.value = value;
    }
}